// Variables
@import 'normalize.css/normalize.css';
@import 'bootstrap/dist/css/bootstrap-grid.min.css';
@import '~rfs/scss';
@import 'variables';
@import 'global';

canvas {
    opacity: 0;
    z-index: -1;
}

#header {
    width: 100%;
    padding: 2em 0;
    position: fixed;
    top: 0;
    z-index: 100000;

    .navbar {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        &-logo {

            &-title {
                @include font-body($weight: $font-weight-heading);
                line-height: 1;
                text-transform: uppercase;
            }
        }

        &-cta {

            &-bag {
                width: 25px;
            }
        }
    }
}

#intro {
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    background: $color-background;

    .intro-imgtrail__img {
        max-width: 250px;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        will-change: transform;
    }

    .intro {

        &-wrap {
            height: 100vh;
            position: relative;
            display: flex;
            z-index: 100000;
        }

        &-btn {
            margin: auto;
            text-align: center;
            padding: 1em 2.4em;
            border: 1px solid $color-text;
            border-radius: 100%;
            transition: background 0.3s ease, color 0.3s ease;
            cursor: pointer;
            
            &:hover {
                background: $color-text;
                color: $color-background;
            }
        }
    }
}

#shop {

    .shop {

        &-category {
            margin-bottom: 5em;
        }

        &-item {

            &-title {
                display: inline-block;
                border-bottom: 1px solid transparent;
                transition: border 0.3s ease;
            }

            &:hover {

                .shop-item-title {
                    border-bottom: 1px solid $color-text;
                }
            }
        }

        &-more {
            margin: 2em auto;
            display: flex;
        }
    }
}

@import 'responsive';