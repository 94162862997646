@import './variables';
@import '~rfs/scss';

*,
*:before,
*:after {
    box-sizing: border-box;
    font-size: $font-size-root;
}

:root {
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: none;
}


html.has-scroll-smooth {
    overflow: hidden;
}

html[data-direction="down"] #header {
    transform: translate3d(0, -100%, 0);
}

.smooth-scroll {
    width: 100vw;
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
}

.scrollbar-track {
    background: rgba(0, 0, 0, 0.2) !important;

    .scrollbar-thumb {
        width: 4px !important;
        background: $color-text !important;
    }
}

.scrollbar-track-y {
    width: 4px !important;
}

body {
    @include font-body();
    font-family: roc-grotesk, sans-serif;
    background-color: $color-background;
    color: $color-text;
    overflow: hidden;
}

section {
    margin: $margin-section 0;
}

a {
    color: $color-text;
    text-decoration: none;
    display: inline-block;
    line-height: 1.6;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    @include font-heading();
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
li,
small {
    @extend %reset-space;
}

h2 {
    @include font-heading();
    // text-transform: uppercase;
}

h3,
h4,
h5,
h6 {
    @include font-subheading();
}

small {
    @include font-small();
}

img {
    max-width: 100%;
}

// Global style
.container {
    position: relative;
}

.title-center {
    display: block;
    margin: auto;
    text-align: center;
}

.link {
    @include font-small();
    @include margin-vertical($size: $space * 2);
    text-transform: uppercase;

    &-icon {
        vertical-align: middle;
    }
}

.btn {
    margin: auto;
    text-align: center;
    padding: 1em 2.4em;
    border: 1px solid $color-text;
    border-radius: 100%;
    transition: background 0.3s ease, color 0.3s ease;
    display: inline-block;
    text-transform: uppercase;
    
    &:hover {
        background: $color-text;
        color: $color-background;
    }
}

#main {
    overflow: hidden;
}

#loading {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: $color-primary;
    color: $color-background;
    z-index: 99999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transform-origin: left;
    @include font-small();


    .loading-logo {
        max-width: 200px;
    }

    .loading-bar {
        margin-top: 10px;
        width: 200px;
        height: 2px;
        position: relative;

        &-nofill {
            opacity: 0.4;
        }

        &-nofill,
        &-filled {
            width: 100%;
            height: 100%;
            position: absolute;
            background: $color-background;
            transform-origin: left;
        }

        &-filled {
            opacity: 1;
            transform: scaleX(0);
        }
    }
}

.border {
    width: 100%;
    border-bottom: 1px solid $color-text;
}


.masking-text {
    overflow: hidden;
    display: block;

    .line {
        position: relative;
    }
}

.reveal-image {
    position: relative;
    overflow: hidden;
    visibility: hidden;

    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        transform-origin: bottom;
    }
}

.parallax {
    width: 100%;
    height: 100vh;
    overflow: hidden;

    &-image {
        width: 100%;
        height: 110vh;
        object-fit: cover;
    }

    &-overlay {
        height: 100%;
        width: 100%;
        background: black;
        opacity: 0.7;
    }
}


#canvas {
    /* make the canvas wrapper fits the document */
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.plane {
    width: 100%;
    height: 50vh;

    img {
        display: none;
    }
}

.text-italic {
    font-style: italic;
}